import React, { useRef, useEffect, useState } from "react";
import { Link } from "gatsby"

import Box from "@material-ui/core/Box";

import SEO from "../components/SEO"
import SocialIcons from '../components/SocialIcons'

import IntroVideo from '../assets/videos/introVideo.mp4';

import '../assets/css/welcome.scss'
import Logo from '../assets/logo.png';

function IndexPage() {
  const videoRef = useRef(null)
  const [isVideoEnding, setIsVideoEnding] = useState(false)

  useEffect(() => {
    videoRef.current.addEventListener("timeupdate", handleHighlightLogo, false);
    return function cleanupListener() {
      videoRef.current.removeEventListener('timeupdate', handleHighlightLogo) // remove listener when leaving from the page
    }
  }, [])

  // Method check current time in video. 2 second before video ends set value to true
  const handleHighlightLogo = () => {
    if(videoRef.current.currentTime > (videoRef.current.duration - 2)) {
      setIsVideoEnding(true)
    }
  }

  return (
    <Box className="page fullScreen" id="welcome-page">
      <SEO title="Welcome" />
      
      <video ref={videoRef} className={`fullSize video ${isVideoEnding ? "fade-out" : ""}`} autoPlay muted>
        <source src={IntroVideo} type="video/mp4"/>
      </video>

      <Box className={`overlay ${isVideoEnding ? "highlight" : ""}`}/>
      
      <Link className={`wrapper-logo padd ${isVideoEnding ? "highlight" : ""}`} to='/home'>
        <img className="logo" alt="Ján Ďurovčík Entertainment - Logo" src={Logo}/>
      </Link>

      <SocialIcons/>
    </Box>
  );
}
export default IndexPage;